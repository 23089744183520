<template>
  <v-dialog :value="dialog" max-width="750px" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">{{
          !selectedCoreBuying.id
            ? $t("coreBuying.addCoreBuying")
            : $t("coreBuying.editCoreBuying")
        }}</span>
        <v-spacer></v-spacer>
        <v-icon @click="close">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col :cols="selectedCoreBuying.id ? '12' : '6'">
              <v-text-field
                outlined
                v-model="selectedCoreBuying.email"
                :label="$t('coreBuying.accessEmail')"
                :placeholder="$t('coreBuying.accessEmail')"
                @input="$v.selectedCoreBuying.email.$touch()"
                @blur="$v.selectedCoreBuying.email.$touch()"
                :error-messages="emailErrors"
                hide-details="auto"
              ></v-text-field>
            </v-col>

            <v-col cols="6" v-if="!selectedCoreBuying.id">
              <v-text-field
                autocomplete="new-password"
                outlined
                v-model="selectedCoreBuying.password"
                :label="$t('coreBuying.password')"
                :placeholder="$t('coreBuying.password')"
                @input="$v.selectedCoreBuying.password.$touch()"
                @blur="$v.selectedCoreBuying.password.$touch()"
                :error-messages="passwordErrors"
                hide-details="auto"
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="selectedCoreBuying.user_detail.company_name"
                :label="$t('coreBuying.companyName')"
                :placeholder="$t('coreBuying.companyName')"
                @input="$v.selectedCoreBuying.user_detail.company_name.$touch()"
                @blur="$v.selectedCoreBuying.user_detail.company_name.$touch()"
                :error-messages="companyNameErrors"
                hide-details="auto"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                maxlength="14"
                v-model="selectedCoreBuying.user_detail.company_phone"
                :label="$t('coreBuying.companyPhone')"
                :placeholder="$t('coreBuying.companyPhone')"
                @input="
                  $v.selectedCoreBuying.user_detail.company_phone.$touch()
                "
                @blur="$v.selectedCoreBuying.user_detail.company_phone.$touch()"
                :error-messages="companyPhoneErrors"
                hide-details="auto"
                outlined
                v-to-us-format
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="selectedCoreBuying.user_detail.company_email"
                :label="$t('coreBuying.companyEmail')"
                :placeholder="$t('coreBuying.companyEmail')"
                @input="
                  $v.selectedCoreBuying.user_detail.company_email.$touch()
                "
                @blur="$v.selectedCoreBuying.user_detail.company_email.$touch()"
                :error-messages="companyEmailErrors"
                hide-details="auto"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="selectedCoreBuying.user_detail.company_reviews_link"
                :label="$t('coreBuying.companyReviewLink')"
                :placeholder="$t('coreBuying.companyReviewLink')"
                hide-details="auto"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="4">
              <google-place
                @onAddressSelect="onAddressSelect"
                :address="selectedCoreBuying.user_detail.address"
                label="coreBuying.locationAddress"
                validationMsg="coreBuying.validations.addressIsRequired"
                :isRequired="true"
              >
              </google-place>
            </v-col>

            <v-col cols="3">
              <v-text-field
                v-model="selectedCoreBuying.user_detail.city"
                :label="$t('coreBuying.city')"
                :placeholder="$t('coreBuying.city')"
                hide-details="auto"
                @input="$v.selectedCoreBuying.user_detail.city.$touch()"
                @blur="$v.selectedCoreBuying.user_detail.city.$touch()"
                :error-messages="cityErrors"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="3">
              <v-text-field
                v-model="selectedCoreBuying.user_detail.state"
                :label="$t('coreBuying.state')"
                :placeholder="$t('coreBuying.state')"
                hide-details="auto"
                @input="$v.selectedCoreBuying.user_detail.state.$touch()"
                @blur="$v.selectedCoreBuying.user_detail.state.$touch()"
                :error-messages="stateErrors"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="2">
              <v-text-field
                v-model="selectedCoreBuying.user_detail.zip_code"
                :label="$t('coreBuying.zip')"
                :placeholder="$t('coreBuying.zip')"
                hide-details="auto"
                @input="$v.selectedCoreBuying.user_detail.zip_code.$touch()"
                @blur="$v.selectedCoreBuying.user_detail.zip_code.$touch()"
                :error-messages="zipErrors"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="selectedCoreBuying.user_detail.representative_name"
                :label="$t('coreBuying.representativeName')"
                :placeholder="$t('coreBuying.representativeName')"
                @input="
                  $v.selectedCoreBuying.user_detail.representative_name.$touch()
                "
                @blur="
                  $v.selectedCoreBuying.user_detail.representative_name.$touch()
                "
                :error-messages="representativeNameErrors"
                hide-details="auto"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                maxlength="14"
                v-model="selectedCoreBuying.user_detail.representative_phone"
                :label="$t('coreBuying.representativePhone')"
                :placeholder="$t('coreBuying.representativePhone')"
                @input="
                  $v.selectedCoreBuying.user_detail.representative_phone.$touch()
                "
                @blur="
                  $v.selectedCoreBuying.user_detail.representative_phone.$touch()
                "
                :error-messages="representativePhoneErrors"
                hide-details="auto"
                outlined
                v-to-us-format
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-select
                outlined
                v-model="selectedCoreBuying.user_detail.language"
                :items="langList"
                item-text="name"
                item-value="value"
                :label="$t('coreBuying.language')"
                hide-details="auto"
                :placeholder="$t('coreBuying.language')"
                @input="$v.selectedCoreBuying.user_detail.language.$touch()"
                @blur="$v.selectedCoreBuying.user_detail.language.$touch()"
                :error-messages="languageErrors"
                return-value
              ></v-select>
            </v-col>

            <v-col cols="6">
              <v-select
                outlined
                v-model="selectedCoreBuying.user_detail.timezone"
                :items="timezoneList"
                :label="$t('coreBuying.timezone')"
                :placeholder="$t('coreBuying.timezone')"
                @input="$v.selectedCoreBuying.user_detail.timezone.$touch()"
                @blur="$v.selectedCoreBuying.user_detail.timezone.$touch()"
                :error-messages="timezoneErrors"
                hide-details="auto"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" dark dense @click="close"> Cancel </v-btn>
        <v-btn
          color="primary"
          :loading="saving"
          :disabled="
            ($v.selectedCoreBuying.$invalid &&
              $v.selectedCoreBuying.$anyDirty) ||
            saving
          "
          @click="save"
        >
          Save
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import { mapActions } from "vuex";
import moment from "moment-timezone";
import { toSimplePhone } from "../../utils/number.utility";
import {
  required,
  email,
  minLength,
  requiredIf,
} from "vuelidate/lib/validators";
import GooglePlace from "../common/GooglePlace.vue";
import { showSnackbar } from "../../eventbus/action.js";
export default {
  name: "CreateAndEditCoreBuying",
  components: {
    GooglePlace,
  },
  props: {
    dialogStatus: {
      required: true,
      type: Boolean,
      default: false,
    },
    selectedCoreBuyingData: {
      required: false,
      type: Object,
      default: () => {
        return { user_detail: {} };
      },
    },
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.selectedCoreBuying.email.$dirty) return errors;
      !this.$v.selectedCoreBuying.email.email &&
        errors.push(this.$t("coreBuying.validations.emailIsInvalid"));
      !this.$v.selectedCoreBuying.email.required &&
        errors.push(this.$t("coreBuying.validations.emailIsRequired"));
      return errors;
    },

    passwordErrors() {
      const errors = [];
      if (!this.$v.selectedCoreBuying.password.$dirty) return errors;
      !this.$v.selectedCoreBuying.password.requiredIf &&
        errors.push(this.$t("coreBuying.validations.passwordIsRequired"));
      !this.$v.selectedCoreBuying.password.minLength &&
        errors.push(this.$t("coreBuying.validations.passwordMinLength"));
      return errors;
    },

    companyNameErrors() {
      const errors = [];
      if (!this.$v.selectedCoreBuying.user_detail.company_name.$dirty)
        return errors;
      !this.$v.selectedCoreBuying.user_detail.company_name.required &&
        errors.push(this.$t("coreBuying.validations.companyNameIsRequired"));
      return errors;
    },

    companyEmailErrors() {
      const errors = [];
      if (!this.$v.selectedCoreBuying.user_detail.company_email.$dirty)
        return errors;
      !this.$v.selectedCoreBuying.user_detail.company_email.email &&
        errors.push(this.$t("coreBuying.validations.companyEmailIsInvalid"));
      !this.$v.selectedCoreBuying.user_detail.company_email.required &&
        errors.push(this.$t("coreBuying.validations.companyEmailIsRequired"));
      return errors;
    },

    companyPhoneErrors() {
      const errors = [];
      if (!this.$v.selectedCoreBuying.user_detail.company_phone.$dirty)
        return errors;
      !this.$v.selectedCoreBuying.user_detail.company_phone.required &&
        errors.push(this.$t("coreBuying.validations.companyPhoneIsRequired"));
      return errors;
    },

    representativeNameErrors() {
      const errors = [];
      if (!this.$v.selectedCoreBuying.user_detail.representative_name.$dirty)
        return errors;
      !this.$v.selectedCoreBuying.user_detail.representative_name.required &&
        errors.push(
          this.$t("coreBuying.validations.representativeNameIsRequired")
        );
      return errors;
    },

    representativePhoneErrors() {
      const errors = [];
      if (!this.$v.selectedCoreBuying.user_detail.representative_phone.$dirty)
        return errors;
      !this.$v.selectedCoreBuying.user_detail.representative_phone.required &&
        errors.push(
          this.$t("coreBuying.validations.representativePhoneIsRequired")
        );
      return errors;
    },

    timezoneErrors() {
      const errors = [];
      if (!this.$v.selectedCoreBuying.user_detail.timezone.$dirty)
        return errors;
      !this.$v.selectedCoreBuying.user_detail.timezone.required &&
        errors.push(this.$t("coreBuying.validations.timezoneIsRequired"));
      return errors;
    },

    stateErrors() {
      const errors = [];
      if (!this.$v.selectedCoreBuying.user_detail.state.$dirty) return errors;
      !this.$v.selectedCoreBuying.user_detail.state.required &&
        errors.push(this.$t("coreBuying.validations.stateIsRequired"));
      return errors;
    },

    cityErrors() {
      const errors = [];
      if (!this.$v.selectedCoreBuying.user_detail.city.$dirty) return errors;
      !this.$v.selectedCoreBuying.user_detail.city.required &&
        errors.push(this.$t("coreBuying.validations.cityIsRequired"));
      return errors;
    },

    zipErrors() {
      const errors = [];
      if (!this.$v.selectedCoreBuying.user_detail.zip_code.$dirty)
        return errors;
      !this.$v.selectedCoreBuying.user_detail.zip_code.required &&
        errors.push(this.$t("coreBuying.validations.zipIsRequired"));
      return errors;
    },

    languageErrors() {
      const errors = [];
      if (!this.$v.selectedCoreBuying.user_detail.language.$dirty)
        return errors;
      !this.$v.selectedCoreBuying.user_detail.language.required &&
        errors.push(this.$t("coreBuying.validations.languageIsRequired"));
      return errors;
    },

    timezoneList() {
      return moment.tz.zonesForCountry("US");
    },
  },

  data() {
    return {
      dialog: this.dialogStatus,
      selectedCoreBuying: this.selectedCoreBuyingData,
      defaultItem: {
        user_detail: {},
      },
      saving: false,
      langList: [
        {
          name: "English",
          value: "en",
        },
        {
          name: "Spanish",
          value: "es",
        },
      ],
    };
  },

  validations: {
    selectedCoreBuying: {
      email: { required, email },
      password: {
        requiredIf: requiredIf(function () {
          return !this.selectedCoreBuying.id;
        }),
        minLength: minLength(8),
      },
      user_detail: {
        representative_phone: { required },
        representative_name: { required },
        company_name: { required },
        company_email: { required, email },
        company_phone: { required },
        address: { required },
        state: { required },
        city: { required },
        zip_code: { required },
        timezone: { required },
        language: { required },
      },
    },
  },

  methods: {
    ...mapActions({
      updateCoreBuying: "coreBuying/updateCoreBuying",
      createCoreBuying: "coreBuying/createCoreBuying",
    }),

    close() {
      this.dialog = false;
      this.$emit("close", false);
      this.selectedCoreBuying = _.cloneDeep(this.defaultItem);
    },

    onAddressSelect(data) {
      this.selectedCoreBuying.user_detail = {
        ...this.selectedCoreBuying.user_detail,
        ...data,
      };
      delete this.selectedCoreBuying.user_detail.valid;
    },

    async save() {
      this.$v.selectedCoreBuying.$touch();
      if (!this.$v.selectedCoreBuying.$invalid) {
        try {
          this.saving = true;
          let payload = _.cloneDeep(this.selectedCoreBuying);
          payload.user_detail.representative_phone = toSimplePhone(
            payload.user_detail.representative_phone
          );
          payload.user_detail.company_phone = toSimplePhone(
            payload.user_detail.company_phone
          );
          if (payload.id) {
            await this.updateCoreBuying({
              ...payload,
            });
          } else {
            await this.createCoreBuying({
              ...payload,
            });
          }
          this.$emit("refresh");
          await this.close();
        } catch ({ message }) {
          const errorMessage =
            typeof message === "object"
              ? Object.values(message)[0][0]
              : message;
          showSnackbar(errorMessage);
        } finally {
          this.saving = false;
        }
      }
    },
  },
};
</script>
