<template>
  <v-container fluid class="down-top-padding pt-0">
    <v-row>
      <v-col cols="12">
        <div>
          <change-password
            v-if="changePasswordDialog"
            userType="3"
            :selectedUser="selectedCoreBuying"
            :dialogStatus="changePasswordDialog"
            @close="changePasswordDialog = false"
            @refresh="initialize(1)"
          ></change-password>
          <create-and-edit-core-buying
            v-if="dialog"
            :selectedCoreBuyingData="selectedCoreBuying"
            :dialogStatus="dialog"
            @close="dialog = false"
            @refresh="initialize(1)"
            ref="createAndEditForm"
          ></create-and-edit-core-buying>
          <confirmation-dialog
            @close="dialogDelete = false"
            @confirm="deleteItemConfirm"
            :dialog="dialogDelete"
            :dialogHeadline="deleteConfirmationHeadline"
          ></confirmation-dialog>
          <v-data-table
            :loading="loading"
            item-key="id"
            class="elevation-0 pb-4 pt-4 coreBuying_list_table"
            :headers="headers"
            :items="coreBuying"
            :expanded.sync="expanded"
            show-expand
            :page.sync="page"
            :custom-sort="customSort"
            :items-per-page="itemsPerPage"
            hide-default-footer
          >
            <template v-slot:top class="px-3">
              <v-toolbar flat class="mb-4 px-3">
                <v-text-field
                  outlined
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('coreBuying.search')"
                  @input="searchCoreBuying(1)"
                  hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="manageUser"
                  color="primary"
                  dark
                  large
                  dense
                  @click="createItem"
                  elevation="4"
                >
                  {{ $t("coreBuying.addCoreBuying") }}
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-row>
                  <v-col cols="12"> </v-col>
                  <v-col cols="4"
                    ><b>{{ $t("coreBuying.companyEmail") }}</b> :
                    {{ item.user_detail.company_email }}
                  </v-col>
                  <v-col cols="4"
                    ><b>{{ $t("coreBuying.companyPhone") }}</b> :
                    {{ item.user_detail.company_phone }}
                  </v-col>
                  <v-col cols="4"
                    ><b>{{ $t("coreBuying.language") }}</b> :
                    {{ item.user_detail.language }}
                  </v-col>
                  <v-col cols="4"
                    ><b>{{ $t("coreBuying.licensed") }}</b> :
                    {{
                      item.user_detail.licensed
                        ? $t("coreBuying.yes")
                        : $t("coreBuying.no")
                    }}
                  </v-col>
                  <v-col cols="4"
                    ><b>{{ $t("coreBuying.timezone") }}</b> :
                    {{ item.user_detail.timezone }}
                  </v-col>
                  <v-col cols="4"
                    ><b>{{ $t("coreBuying.services") }}</b> :
                    {{ getServices(item.services) }}
                  </v-col>
                  <v-col cols="4"
                    ><b>{{ $t("coreBuying.room") }}</b> :
                    {{ getRooms(item.rooms) }}
                  </v-col>
                  <v-col cols="4"
                    ><b>{{ $t("coreBuying.address") }}</b> :
                    {{ item.user_detail.address }},
                    {{ item.user_detail.state }},
                    {{ item.user_detail.zip_code }}
                  </v-col>
                </v-row>
              </td>
            </template>
            <template v-slot:item.created_at="{ item }">
              {{ item.created_at | datefilter }}
            </template>
            <template v-if="manageUser" v-slot:item.actions="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark icon v-bind="attrs" v-on="on">
                    <v-icon color="primary">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    @click="
                      selectItem(item);
                      dialog = true;
                    "
                    ><v-icon color="#1b5e20" medium>mdi-pencil</v-icon
                    >{{ $t("coreBuying.update") }}</v-list-item
                  >
                  <v-list-item
                    @click="
                      selectedCoreBuying = item;
                      dialogDelete = true;
                    "
                    ><v-icon color="#1b5e20" medium>mdi-delete</v-icon
                    >{{ $t("coreBuying.delete") }}</v-list-item
                  >
                  <v-list-item>
                    <v-switch
                      v-model="item.is_active"
                      :label="
                        item.is_active
                          ? $t('coreBuying.block')
                          : $t('coreBuying.unblock')
                      "
                      @change="activation(item)"
                    ></v-switch>
                  </v-list-item>
                  <v-list-item
                    @click="
                      selectItem(item);
                      changePasswordDialog = true;
                    "
                    ><v-icon color="#1b5e20" medium>mdi-account-key</v-icon
                    >{{ $t("coreBuying.changePassword") }}</v-list-item
                  >
                </v-list>
              </v-menu>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="initialize(1)"> Reset </v-btn>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination
              v-if="coreBuying.length > 0"
              v-model="meta.current_page"
              :length="meta.last_page"
              @input="initialize"
            ></v-pagination>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ConfirmationDialog from "../components/common/ConfirmationDialog";
import CreateAndEditCoreBuying from "../components/members/CreateAndEditCoreBuying";
import { VIEW_USER, MANAGE_USER } from "@/constants/permission";
import _ from "lodash";
import { showSnackbar } from "../eventbus/action.js";
import ChangePassword from "../components/common/ChangePassword";
export default {
  name: "CoreBuying",
  components: {
    ChangePassword,
    ConfirmationDialog,
    CreateAndEditCoreBuying,
  },
  data() {
    return {
      payment_method: null,
      manageUser: false,
      viewUser: false,
      dialog: false,
      associateDialog: false,
      changePasswordDialog: false,
      expanded: [],
      page: 1,
      pageCount: 2,
      itemsPerPage: 50,
      dialogDelete: false,
      clearDueDialog: false,
      search: "",
      headers: [
        { text: "", value: "data-table-expand", width: "4%" },
        {
          text: this.$t("coreBuying.id"),
          align: "start",
          value: "id",
          width: "7%",
        },
        {
          text: this.$t("coreBuying.joinDate"),
          value: "created_at",
          width: "10%",
        },
        {
          text: this.$t("coreBuying.companyName"),
          value: "user_detail.company_name",
          width: "10%",
          sortable: false,
        },
        {
          text: this.$t("coreBuying.email"),
          value: "email",
          width: "20%",
        },
        {
          text: this.$t("coreBuying.password"),
          value: "p_token",
          width: "10%",
          sortable: false,
        },
        {
          text: this.$t("coreBuying.repName"),
          align: "start",
          value: "user_detail.representative_name",
          width: "10%",
          sortable: false,
        },
        {
          text: this.$t("coreBuying.repPhone"),
          value: "user_detail.representative_phone",
          width: "10%",
          sortable: false,
        },
        {
          text: this.$t("coreBuying.paymentMethod"),
          value: "user_subscription.payment_method.name",
          width: "10%",
          sortable: false,
        },
        {
          text: this.$t("coreBuying.actions"),
          value: "actions",
          width: "10%",
          sortable: false,
        },
      ],
      previousIndex: [],
      previousOrder: [],
      selectedCoreBuying: {
        user_detail: {},
        user_subscription: {},
      },
      payload: {},
      defaultItem: {
        services: [
          { id: 10, name: "Posting" },
          { id: 11, name: "Broadcasting" },
        ],
        user_detail: {
          language: "en",
          timezone: "America/Los_Angeles",
        },
        user_subscription: {},
      },
      loading: false,
      deleteConfirmationHeadline: this.$t("coreBuying.deleteHeadline"),
    };
  },
  computed: {
    ...mapGetters({
      coreBuying: "coreBuying/getCoreBuying",
      meta: "coreBuying/getMeta",
      user: "user/getUser",
    }),
  },
  mounted() {
    this.initialize(1);
  },

  methods: {
    ...mapActions({
      getCoreBuying: "coreBuying/getCoreBuying",
      deleteCoreBuying: "coreBuying/deleteCoreBuying",
      activateCoreBuying: "coreBuying/activateCoreBuying",
      setHeaderMenuItems: "common/setHeaderMenuItems",
    }),

    async initialize(page) {
      this.page = page;
      let menuItems = [
        {
          title: this.$t("commonLayout.members"),
          path: "/pages/members",
        },
        {
          title: this.$t("commonLayout.associates"),
          path: "/pages/associates",
        },
        {
          title: this.$t("commonLayout.coreBuying"),
          path: "/pages/core-buying",
        },
      ];
      const userPermission = this.user.permission.map((val) => val.name);
      this.manageUser = userPermission.includes(MANAGE_USER);
      this.viewUser = userPermission.includes(VIEW_USER);
      if (!this.viewUser) {
        menuItems = [];
      }
      this.setHeaderMenuItems(menuItems);
      this.loading = true;
      try {
        await this.getCoreBuying({
          search: this.search,
          page: this.page,
          per_page: this.itemsPerPage,
          ...this.payload,
        });
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showSnackbar(errorMessage);
      } finally {
        this.loading = false;
      }
    },
    searchCoreBuying: _.debounce(function (page) {
      this.initialize(page);
    }, 500),

    selectItem(item) {
      this.selectedCoreBuying = _.cloneDeep(item);
    },

    createItem() {
      this.selectedCoreBuying = _.cloneDeep(this.defaultItem);
      this.dialog = true;
    },

    getServices(data) {
      return data.map((val) => val.name).join(", ");
    },

    getRooms(data) {
      return data && data.map((val) => val.name).join(", ");
    },

    customSort(items, index, isDescending) {
      if (
        _.isEqual(this.previousIndex, index) &&
        _.isEqual(this.previousOrder, isDescending)
      ) {
        return items;
      }
      this.previousIndex = index;
      this.previousOrder = isDescending;
      this.payload = {};
      if (isDescending.length && isDescending[0]) {
        this.payload = { ...this.payload, sortOrder: "desc", sortBy: index[0] };
      } else if (isDescending.length && !isDescending[0]) {
        this.payload = { ...this.payload, sortOrder: "asc", sortBy: index[0] };
      }
      this.initialize(this.page);
      return this.coreBuying;
    },

    async deleteItemConfirm() {
      this.loading = true;
      try {
        await this.deleteCoreBuying({
          id: this.selectedCoreBuying.id,
        });
        this.dialogDelete = false;
        this.initialize(1);
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showSnackbar(errorMessage);
      } finally {
        this.loading = false;
      }
    },
    async activation(coreBuying) {
      this.loading = true;
      try {
        await this.activateCoreBuying({
          id: coreBuying.id,
        });
        this.dialogDelete = false;
        this.initialize(this.page);
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showSnackbar(errorMessage);
      } finally {
        this.loading = false;
      }
    },
  },

  destroyed() {
    this.setHeaderMenuItems([]);
  },
};
</script>
