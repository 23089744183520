<template>
  <v-dialog v-model="dialog" persistent max-width="550px">
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ $t("member.changePassword") }}
        </span>
        <v-spacer></v-spacer>
        <v-icon @click="close">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                autocomplete="new-password"
                outlined
                type="password"
                v-model="current_password"
                :label="$t('member.currentPassword')"
                :placeholder="$t('member.currentPassword')"
                @input="$v.current_password.$touch()"
                @blur="$v.current_password.$touch()"
                :error-messages="currentPasswordErrors"
                hide-details="auto"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                outlined
                type="password"
                v-model="password"
                :label="$t('member.password')"
                :placeholder="$t('member.password')"
                @input="$v.password.$touch()"
                @blur="$v.password.$touch()"
                :error-messages="passwordErrors"
                hide-details="auto"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                outlined
                type="password"
                v-model="password_confirmation"
                :label="$t('member.confirmationPassword')"
                :placeholder="$t('member.confirmationPassword')"
                @input="$v.password_confirmation.$touch()"
                @blur="$v.password_confirmation.$touch()"
                :error-messages="confirmationPasswordErrors"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" dark dense @click="close">
          {{ $t("member.cancel") }}</v-btn
        >
        <v-btn
          color="primary"
          :loading="updating"
          :disabled="($v.$invalid && $v.$anyDirty) || updating"
          @click="update"
        >
          Save
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import { showSnackbar } from "../../eventbus/action.js";
export default {
  name: "ChangePassword",
  props: {
    dialogStatus: {
      required: true,
      type: Boolean,
      default: false,
    },
    selectedUser: {
      required: false,
      type: Object,
      default: () => {
        return { user_detail: {} };
      },
    },
    userType: {
      required: false,
      type: String,
    },
  },
  computed: {
    currentPasswordErrors() {
      const errors = [];
      if (!this.$v.current_password.$dirty) return errors;
      !this.$v.current_password.required &&
        errors.push(this.$t("member.validations.currentPasswordIsRequired"));
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required &&
        errors.push(this.$t("member.validations.passwordIsRequired"));
      !this.$v.password.minLength &&
        errors.push(this.$t("member.validations.passwordMinLength"));
      return errors;
    },
    confirmationPasswordErrors() {
      const errors = [];
      if (!this.$v.password_confirmation.$dirty) return errors;
      !this.$v.password_confirmation.required &&
        errors.push(
          this.$t("member.validations.confirmationPasswordIsRequired")
        );
      !this.$v.password_confirmation.minLength &&
        errors.push(
          this.$t("member.validations.confirmationPasswordMinLength")
        );
      return errors;
    },
  },

  data() {
    return {
      updating: false,
      current_password: null,
      password: null,
      password_confirmation: null,
      dialog: this.dialogStatus,
    };
  },

  validations: {
    current_password: { required },
    password: { required, minLength: minLength(8) },
    password_confirmation: { required, minLength: minLength(8) },
  },

  methods: {
    ...mapActions({
      updateMemberPassword: "members/updateMemberPassword",
      updateAdminPassword: "admins/updateAdminPassword",
    }),

    close() {
      this.dialog = false;
      this.$emit("close", false);
      this.current_password = null;
      this.password = null;
      this.password_confirmation = null;
    },

    async update() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.updating = true;
          const request = {
            id: this.selectedUser.id,
            current_password: this.current_password,
            password: this.password,
            password_confirmation: this.password_confirmation,
          };
          switch (this.userType) {
            case "member":
              await this.updateMemberPassword(request);
              break;
            case "admin":
              await this.updateAdminPassword(request);
              break;
          }

          this.$emit("refresh");
          await this.close();
        } catch ({ message }) {
          const errorMessage =
            typeof message === "object"
              ? Object.values(message)[0][0]
              : message;
          showSnackbar(errorMessage);
        } finally {
          this.updating = false;
        }
      }
    },
  },
};
</script>
